import { deviceService } from '@/api';
import { DeviceStatusEnum } from '@/resource/enum';
import { DeviceResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddDevice extends Vue {
  /**
   * dialog 显示状态
   */
  @PropSync('visible', { default: false }) public dialogVisible!: boolean;
  /**
   * 企业信息数据对象
   */
  @Prop({ required: false }) public device: DeviceResource | null = null;

  /**
   * dialog 标题
   */
  public title = '';
  /**
   * 提交loading状态
   */
  public submitLoading = false;

  /**
   * 分类数据
   */
  public categoryList: Array<{ label: string; value: number }> = [];

  /**
   * 表单字段栅格数
   */
  public formColSpan = 12;

  /**
   * 表单数据对象
   */
  public formData: {
    deviceName: string;
    categoryId: number | null;
    printHead: string;
    colorInfo: string;
    status: DeviceStatusEnum | string;
    remark: string;
  } = {
    deviceName: '',
    categoryId: null,
    printHead: '',
    colorInfo: '',
    status: DeviceStatusEnum.enable,
    remark: ''
  };

  /**
   * 表单验证规则
   */
  public formRules = {
    deviceName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierDevice.inputDeviceName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          deviceService
            .checkNameRepeat(value, this.device?.id as number)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.deviceNameRepeat'));
                return;
              }

              callback();
            })
            .catch((error: string) => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    categoryId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierDevice.selectCategory')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    status: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.selectStatus')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 操作
   */
  public isCreate: boolean | null = true;

  /**
   * 初始化标记
   */
  private isInitalized = false;

  /**
   * dialog开启事件
   */
  public handleDialogOpen(): void {
    this.init();

    if (!this.device) {
      this.isCreate = true;
      this.title = 'supplierDevice.addDevice';
      return;
    }

    this.isCreate = false;
    this.title = 'supplierDevice.editDevice';

    Object.assign(this.formData, this.device);
  }

  /**
   * 提交数据
   */
  public handleSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      this.openSubmitLoading();

      try {
        if (this.isCreate) {
          await deviceService.post(this.formData as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
        } else {
          await deviceService.put(this.formData as any);
          Message.success(translation('operationRes.editSuccess'));
          this.$emit('edit-success', this.formData);
        }

        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.closeSubmitLoading();
      }
    });
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.$emit('dialog-closed');
    this.resetForm();
    (this.$refs.form as Form).resetFields();
  }

  private resetForm(): void {
    this.formData = {
      deviceName: '',
      categoryId: null,
      printHead: '',
      colorInfo: '',
      status: DeviceStatusEnum.enable,
      remark: ''
    };
  }

  /**
   * 初始化dialog所需数据
   */
  private init(): void {
    // 判断是否已初始化
    if (this.isInitalized) return;

    this.loadCategoryData();
  }

  /**
   * 载入分类数据
   */
  private async loadCategoryData(): Promise<void> {
    try {
      const categoryList = (await deviceService.getAllUsingDeviceClassify()) || [];
      this.categoryList = categoryList.map(category => {
        return {
          label: category.categoryName,
          value: category.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 开启loading
   */
  private openSubmitLoading(): void {
    this.submitLoading = true;
  }

  /**
   * 关闭loading
   */
  private closeSubmitLoading(): void {
    this.submitLoading = false;
  }

  /**
   * 关闭dialog
   */
  private closeDialog(): void {
    this.dialogVisible = false;
  }
}
